.mainBox {
    padding-top: 86px;
    color: #fff;
    background-color: #27272a;
}

.footerPadding {
    padding-left: 9%;
    padding-right: 9%;
}

/* footer header */
.footerNavMenu {
    padding-top: 80px;
    padding-bottom: 130px;
}

.footerHeader span {
    letter-spacing: -0.34px;
    color: #ffffff;
    line-height: 42px;
    font-size: 34px;
    font-weight: 600;
}

.footerNavMenu img {
    width: 146px;
    height: fit-content;
}

.footerNavMenu .loadingButtonStyle2 {
    padding: 6px 10px;
    font-size: 12px;
    color: #27272a;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 38px;
}

.footerNavMenu a {
    padding: 0px;
}

.logoImg {
    padding: 69px 0px;
    display: flex;
    justify-content: center;
}

.socialIcon img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.socialIcon span {
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.3px;
    font-size: 15px;
    margin-right: 20px;
    margin-left: 14px;
}

.footerFlex {
    display: flex;
    align-items: center;
}

.socialIcon {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100%;
    cursor: pointer;
}

.footerLinks a {
    font-size: 15px;
    font-weight: 400;
    color: #696969;
    margin-left: 20px;
    letter-spacing: 0.3px;
    text-decoration: none;
}

.footerLinks span {
    font-size: 15px;
    color: #696969;
    /* margin-left: 20px; */
    letter-spacing: 0.3px;
    text-decoration: none;
}

.footerLinks a:hover {
    color: #fff;
    /* font-weight: 300; */
}

/* footer menu link css */
span.footerSubHeading {
    font-size: 16px !important;
    line-height: 24px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0.16px;
}

ul.footerMenu {
    list-style: none;
    margin-top: 8px;
}

ul.footerMenu li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.16px;
    font-weight: 400;
}

/* footer bottom menu */
.footerBottomMenu {
    padding: 26px 9%;
    border-top: 1px solid #3c3c3c;
}

img.footerLogoStyle {
    margin-top: 10px;
}

@media (max-width: 1440px) {
    .footerLinks span {
        font-size: 12px;
    }
    .footerLinks a {
        font-size: 12px;
    }
    .socialIcon span {
        font-size: 12px;
    }
    .socialIcon img {
        width: 13px;
        height: 13px;
    }
}

@media (max-width: 1320px) {
    .footerLinks a {
        margin-left: 0px;
        margin-right: 12px;
    }
    .footerLinks span {
        margin-right: 10px;
    }
    .footerBottomNavBar {
        display: flex;
    }
}

@media (max-width: 1024px) {
    .footerLinks span {
        margin-left: 0px;
        margin-top: 14px;
    }
    .socialIcon span {
        margin-left: 0px;
    }
    .mobileDisplayBlock {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .footerNavMenu {
        padding-top: 40px;
        padding-bottom: 60px;
    }
    .footerBottomNavBar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .footerLinks a {
        margin-left: 0px;
        flex-basis: calc(50.33% - 20px);
        margin-bottom: 10px;
    }
    .footerBottomMenu {
        padding: 20px 9%;
    }
    .footerFlex.socialIcon {
        margin-bottom: 20px;
        align-items: baseline;
        justify-content: flex-start;
    }
}

@media (max-width: 520px) {
    .mobileDisplayBlock {
        margin-top: 18px;
    }
    ul.footerMenu li a {
        font-size: 14px;
        line-height: 25px;
    }
    .footerHeader span {
        font-size: 26px;
        line-height: 32px;
    }
    .footerFlex.socialIcon {
        margin-bottom: 0px;
    }
    .footerLogoStyle {
        margin-bottom: 14px;
    }
}

@media (max-width: 420px) {
    .footerBottomMenu {
        padding: 20px 6%;
    }
}
.flagIcon {
    width: 44px;
}
