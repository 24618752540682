.accountSetupStyle h3{
    font-size: 34px;
    font-weight: 700;
    letter-spacing: -0.34px;
    color: #27272A;
    line-height: 42px;
    margin-top: 26px;
    margin-bottom: 20px;
}

.accountSetupStyle h6{
    color: #048B67;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.accountSetupStyle p{
    margin-top: 20px;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #27272A;
    line-height: 24px;
}

.accountSetupStyle{
    max-width: 386px;
}

.accountSettingStyle img{
    min-height: 280px;
}

.accountSettingStyle h3{
    max-width: 701px;
    font-weight: 700;
}

.marginWrapper{
    margin-top: -8rem;
}

.accountSetupStyle {
    margin-top: 42px;
}

.marginRightStyle{
    margin-right: auto;
}

.workBanner .headingStyle h1{
    max-width: 592px;
}

.workBanner .headingStyle h5{
    max-width: 378px;
}

img.backgroundImgStyle {
    position: absolute;
    top: -150px;
    right: 8%;
    width: 315px;
    height: 311px;
}

/* media query */
@media (max-width: 1640px) {
    .marginLeftStyle .accountSetupStyle{
        margin-left: auto;   
    }   
}

@media (max-width: 1200px) {
    .accountSetupStyle h3, .accountSettingStyle h3{
        font-size: 26px;
    }
    .accountSetupStyle h6{
        font-size: 16px;
    }
    .accountSetupStyle p{
        font-size: 14px;
    }
    .workBanner .headingStyle h1, .workBanner .headingStyle h5{
        max-width: inherit;
    }
    img.backgroundImgStyle {
        top: -120px;
        right: 9%;
        width: 240px;
        height: 240px;
    }
}

@media (max-width: 900px) {
    .smTopMargin{
        margin-top: 30px;
    }
    .accountSettingStyle img {
        min-height: auto;
    }
    .mobileOrder{
        order: 2 !important;
    }
    .marginWrapper{
        margin-top: 0px;
    }
    .accountSetupStyle{
        max-width:max-content;
        margin-top: 0px;
    }
    img.backgroundImgStyle {
        top: -82px;
        width: 200px;
        height: 200px;
    }
    .accountSettingStyle {
        padding-top: 60px;
    }
}

@media (max-width: 520px) {
    .accountSettingStyle h3{
        max-width:max-content;
    } 
    
    .accountSetupStyle{
        margin-top: 0px;
    }
}