.navCalStyle{
    display: flex;
    justify-content: space-around;
    margin-top: 32px;
    background: #F8F8F8;
    padding: 16px 50px;
    border-radius: 10px;
}

.checkboxStyle{
    color: #0F9672 !important;
}

.flexDisplayItems{
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkboxStyle{
    position: relative;
}

.checkboxStyle svg{
    position: absolute;
}

.textInputStyle{
    min-width: 350px;
}

.textInputStyle input{
    height: 1rem;
    outline: none !important;
    font-size: 14px;
    padding: 12px;
}

.textInputStyle .Mui-focused{
    border-color: #0F9672;
}

.textInputStyle:focus-visible{
    outline: none !important;
}

.checkboxStyle:after{
    width: 20px;
    height: 20px;
    border: 0px;
    content: "";
    border-radius: 3px;
}

.checkboxStyle .Mui-checked:before{
    width: 10px;
    height: 10px;
    content: "";
    position: absolute;
    border-radius: 1px
}

.checkboxStyle label{
    font-size: 20px;
    font-weight: 600;
    color: #00773E;
    margin-left: 12px;
}

.labelStyle{
    color: #27272A;
    font-size: 16px;
    margin-right: 10px;
    white-space: normal;
    overflow: auto;
    text-overflow: unset;
}

.divLoadButton{
    display: flex;
    justify-content: center;
    padding: 4px 0 40px;
}

.knowledgeBaseStyle{
    height: 60vh;
    border: 2px solid #0F9672;
    text-decoration: none;
    font-weight: normal;
    border-radius: 25px;
    padding: 5% 10%;
    font-size: 16px;
    text-align: left;
    overflow: auto;
    margin-top: 32px;
}

.knowledge-base-filters .checkboxStyle,
.knowledge-base-filters h4 {
    color: black !important;
}

@media (max-width: 600px) {
    .navCalStyle{
        display: block;
        padding: 24px;
        text-align: center;
    }
    .navCalStyle label{
        padding: 6px 10px;
        margin: 0px !important;
    }
    .flexDisplayItems{
        display: block;
    }
    .textInputStyle{
        margin-top: 10px;
        width: 100%;
    }
}

@media (max-width: 900px) {
    .knowledge-base-filters h4{
        font-size: 16px;
        margin-left: 2px !important;
    }
    .checkboxStyle svg{
        font-size: 18px;
    }
}

.newsroomImage {
    height: 25% !important;
}