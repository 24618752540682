/* FeaturesMoolahMate */
.aboutFeatureStyle .subHeadingStyle h3{
  max-width: 701px;  
}

.aboutFeatureStyle  .subHeadingStyle h5{
    max-width: 650px;
}

ul.featurePoints {
    list-style: none;
}

ul.getMorePoints {
    list-style: none;
    margin-bottom: 20px;
}

.getMorePointsStyle {
    display: flex;
    max-width: 580px;
}

ul.getMorePoints li {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 30px;
    color: #27272a;
    font-weight: 500;
    max-width: 579px;
}

ul.getMorePoints img {
    height: 12px;
    margin-top: 10px;
    margin-right: 12px;
}

.featurePointsStyle{
    display: flex;
    max-width: 580px;
}

ul.featurePoints li {
    font-size: 20px;
    padding-top: 32px;
    padding-bottom: 30px;
    letter-spacing: 0px;
    line-height: 30px;
    color: #27272A;
    font-weight: 700;
    max-width: 579px;
    border-bottom: 1px solid #f4f1ea;
}

ul.featurePoints img {
    height: 12px;
    margin-top: 10px;
    margin-right: 12px;
}

/* about owner style here */
.aboutOwnerStyle p{
    font-size: 16px;
    color: #27272A;
    line-height: 24px;
    letter-spacing: 0.16px;
    margin-bottom: 16px;
}

.personalManagementStyle .subHeadingStyle h2{
    max-width: 444px;
}

.aboutOwnerStyle{
    max-width: 539px;
    margin-top: 36px;
}

/* about ceo review style */
.aboutCeoReview {
    margin-top: 8rem;
}

.founderTxtStyle{
    letter-spacing: 0px;
    color: #27272A;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
}

.founderQuoteStyle{
    font-size: 16px;
    color: #27272A;
    line-height: 24px;
    max-width: 460px;
    font-weight: 400;
    margin-top: 21px;
}

.curveIcon {
    position: absolute;
    left: -16px;
    top: 40%;
}

/* more about founder */
.moreAboutFounder h4{
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.28px;
    font-weight: 600;
    color: #27272A;
    margin-bottom: 24px;
}

.moreAboutFounder .subTxtHeading{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.16px;
    color: #27272A;
}

.moreAboutFounder .subPointerHeading{
    letter-spacing: 0px;
    font-size: 20px;
    line-height: 30px;
    color: #27272A;
    font-weight: 700;
    margin-top: 60px;
}

/* AboutFauzi */
.fauziHeading .para1 {
    font: normal normal bold 20px/30px Poppins;
    color: #27272a;
}

.fauziHeading .para2 {
    font: normal normal normal 16px/24px Poppins;
    color: #27272a;
    width: 468px;
}

/* MoreAboutFauzi */
.moreFauziHeading h4 {
    font: normal normal 600 28px/36px Poppins;
    color: #27272a;
    letter-spacing: -0.28px;
}

.moreFauziHeading p {
    font: normal normal normal 16px/24px Poppins;
    letter-spacing: 0.2px;
    color: #27272a;
    line-height: 26px;
    margin-top: 12px;
    font-weight: 300;
}

.moreFauziHeading h3 {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: -0.56px;
    color: #27272a;
}

/* CareerHighlights */
ul.whatCareerPointStyle {
    list-style: none;
}

ul.whatCareerPointStyle li {
    display: flex;
    margin-top: 10px;
    font-size: 16px;
    line-height: 30px;
    color: #27272a;
    letter-spacing: 0.16px;
    font-weight: 600;
}

ul.whatCareerPointStyle img {
    height: 12px;
    margin-top: 6px;
    margin-right: 12px;
}

/* vision */
.titleHeading{
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 0.2px;
    color: #27272A;
}

.subTextStyle{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.16px;
    color: #27272A;
    margin-top: 10px;
}

.benefitsHeading {
    margin-top: 24px;
}

.benefitsofMoolahStyle .subHeadingStyle h6{
    max-width: initial;
}

.boldTextStyle{
    font-weight: 600;
    font-size: 16px;
}

.mobileViewText{
    display: none;
}

.getSmarterStyle h4{
    letter-spacing: -0.28px;
    line-height: 36px;
    color: #27272A;
    font-size: 28px;
    font-weight: 600;
}

.personalManagementStyle {
    padding: 116px 111px 124px;
    background: #FCFBF8 0% 0% no-repeat padding-box;
}

/* our vision */
.ourVisionStyle{
    background: #27272A;
    padding: 100px 110px;
    border-radius: 1px;
}

.ourVisionPadding{
    margin-top: 100px;
    padding: 0px 9%;
    position: relative;
}

.visionHeading{
    color: #29DE94;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 18px;
}

.visionSubHeading{
    letter-spacing: -0.34px;
    color: #FFFFFF;
    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    max-width: 935px;
}

.visionSubText{
    letter-spacing: -0.28px;
    color: #FFFFFF;
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    max-width: 935px;
}

.visionCardStyle p{
    color: #FFFFFF;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
}

.visionCardStyle{
    max-width: 280px;
    padding: 20px 0px;
    height: 140px;
}

img.relativeCurveStyle {
    position: absolute;
    top: 64px;
    right: -165px;
    width: 582px;
    height: 585px;
}

.getSmarterStyle{
    max-width: 480px;
}

.relativeCurveImg {
    position: absolute;
    top: -134px;
    right: -90px;
    z-index: -1;
}

.sectionTopPadding{
    padding-top: 80px;
}

.sectionTopMargin{
    position: relative; 
    z-index: 1;
}

.aboutTopMargin{
    margin-top: -5rem;
}

.aboutVsionBgStyle{
    padding-top: 56rem;
    margin-top: -48rem;
}

.commonProductPadding{
    padding-top: 155px;
    padding-bottom: 155px;
}

/* media query for about us */
@media (max-width: 1600px) {
    img.relativeCurveStyle {
        right: -196px;
        top: 90px;
        height: 490px;
    }
    .descriptionText{
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
    }   
}

@media (max-width: 1400px) {
    img.relativeCurveStyle {
        right: -242px;
        height: 480px;
    }
    .getSmarterStyle {
        max-width: 430px;
    }
}

@media (max-width: 1200px) {
    .getSmarterStyle, .founderQuoteStyle, .aboutFeatureStyle .subHeadingStyle h3, .featurePointsStyle, .aboutFeatureStyle  .subHeadingStyle h5, .personalManagementStyle .subHeadingStyle h2, .moreAboutFounder h4, .moreAboutFounder .subTxtHeading, .visionSubHeading, .visionSubText{
        max-width: initial;
    }
    .homeBannerStyle .headingStyle h1, .homeBannerStyle .headingStyle h5{
        max-width: initial;
    }
    .aboutOwnerStyle{
        max-width: initial;
        margin-top: 4px;
    }
    .moreAboutFounder .subPointerHeading {
        margin-top: 30px;
    }
    img.relativeCurveStyle{
        display: none;
    }
    .aboutCeoReview {
        margin-top: 0rem;
    }
    .ourVisionPadding {
        margin-top: 30px;
        padding: 0px;
    }
    .ourVisionStyle {
        padding: 80px; 
    }
    .personalManagementStyle {
        padding: 60px;
        background: #FCFBF8 0% 0% no-repeat padding-box;
    }
}

@media (max-width: 900px){
    .mobileViewText{
        display: block;
        letter-spacing: 0.2px;
        font-size: 20px;
        color: #27272A;
        font-weight: 400;
        line-height: 30px;
        margin-top: 12px;
    }
    .aboutTopMargin {
        margin-top: -3rem;
    }
    .commonProductPadding{
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .sectionTopMargin{
        margin-top: 120px;
    }   
    .founderTxtStyle{
        font-size: 18px;
    }
    .visionCardStyle {
        max-width: initial;
        padding: 20px 0px;
        height: 100px;
    }
    .smBoderBottom:last-child {
        border-top: 2px solid rgb(89, 89, 90);
    }
}

@media (max-width: 520px) {
    ul.featurePoints li{
        font-size: 14px;
        padding: 12px 0px;
    }
    ul.featurePoints{
        margin-top: 14px;
    }
    .aboutFeatureStyle {
        padding-right: 40px;
    }
    .smPaddingStyle{
        padding-top: 0px;
    }
    .ourVisionStyle{
        padding: 100px 50px;   
    }
    .imgMarginAuto {
        padding-top: 30px;
    } 
    .visionCardStyle {
        max-width: inherit;
        padding-top: 10px;
        height: auto;
        padding-bottom: 22px;
    }
    .moreAboutFounder h4, .getSmarterStyle h4{
        font-size: 20px;
        line-height: 30px;
    }
    .visionSubHeading {
        font-size: 26px;
        line-height: 40px;
    }
    .visionCardStyle p {
        font-size: 14px;
        line-height: 20px;
    }
    .subTextStyle {
        font-size: 14px;
    }
    .titleHeading {
        font-size: 18px;
    }
    .aboutVsionBgStyle{
        padding-top: 2rem;
        margin-top: 0px;
    }
    .aboutTopMargin{
        margin-top: 0px;
    }
   
}
