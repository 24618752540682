body,
h1,
h2,
h3,
h4,
h5,
h6, textarea {
    font-family: Poppins !important;
}
.pac-container:after {
    height: 0;
    padding: 0;
}
.customDateSelector .MuiButtonBase-root.Mui-selected,
.PrivatePickersYear-yearButton.Mui-selected {
    color: #fff;
}

.new-sticky {
    position: sticky;
    position: -webkit-sticky !important;
    top: 60px;
    background: #f8f8f8;
    z-index: 9;
}

.sortingReportIcon{
    opacity: 1;
    height: 20px;
    width: 22px;
    color: #637381;
}

.loading-indicator:before {
    content: '';
    background: #000000cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.loading-indicator:after {
    content: 'Loading...';
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
}

button.Mui-selected {
    color: #fff !important;
}
.top-to-btm {
    position: relative;
}

.icon-position {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
}

.icon-style {
    background-color: #00773E;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all 0.5s ease-in-out;
}

.icon-style:hover {
    animation: none;
    background: #fff;
    color: #00773E;
    border: 2px solid #00773E;
}

@keyframes movebtn {
    0% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(20px);
    }
    50% {
        transform: translateY(0px);
    }
    75% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}

.divTableStyle{
    background: #fff;
    border: 2px solid #155041;
    border-radius: 10px;
    overflow: auto;
    margin-top: 32px;
}

.productServiceStyle{
    box-sizing: border-box;
    flex-direction: row;
    color: rgb(75, 75, 75);
    padding: 30px 10% 40px 10%;
}

/* display flex in web and block in app */
.mdFlexDisplay{
    display: flex;
    justify-content: center; 
}

/* width */
.customSliderStyle::-webkit-scrollbar {
    width: 10px;
}

.backToMenuStyle {
    color: #27272A;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px;
    margin-top: 32px;
}

.backToMenuStyle:hover {
    background: none;
}

.backToMenuStyle svg{
    margin-right: 6px;
    font-size: 16px;
    font-weight: 600;
}

.smDisplayMobile{
    font-size: '12px';
}

.MuiAutocomplete-root div:first-of-type:hover{
    border-color: #0F9672;
}
  
.customSliderStyle::-webkit-scrollbar-track {
    background: #f1f1f1;
    margin: 18px;
    border-radius: 22px;
}
   
.customSliderStyle::-webkit-scrollbar-thumb {
    background: #0F9672; 
    border-radius: 22px;
}
  
.customSliderStyle::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

/* custom padding for login and signup screen */
.customPaddingStyle{
    padding-top: 0px !important;
    padding-left: 90px !important;
}

.customAlignPaddingStyle{
    padding: 0px 16% 24px calc(4% + 90px);
}

.onboardingHeadingStyle .subHeadingStyle h3{
    max-width: initial;
}

.mdCustomPadding{
    padding-left: 90px;
}

.stepperStyle {
    padding-top: 12px;
    padding-left: 90px;
    background: #FCFBF8;
    padding-right: 16%;
}

.stepperStyle .stepSlider{
    padding-left: 5%;
}

.stepperBtnStyle button{
    max-width: 150px;
    min-width: 150px;
    margin-bottom: 15px;
    margin-top: 10px;
    padding: 6px 0px;
}

/* customized padding in onboarding */
.mdCustomPadding .hubPadding {
    padding-left: 4.3%;
}

.justifyContentStyle{
    justify-content: end;
}

.extraPaddingStyle{
    padding-left: calc(36px + 4%) !important;
    padding-top: 0px;
}

/* media query */
@media (max-width: 1199px) {
    .justifyContentStyle{
        justify-content: space-between !important;
    }
    .mdPaddingTop{
        padding-top: 16px !important;
    }
    .middleBorderStyle{
        display: none;
    }
    .paddingRight{
        padding-right: 0px !important;
    }
}

@media (max-width: 899px) {
    .productServiceStyle{
        padding: 60px 4% !important;
    }
    .mdFlexDisplay{
        display: block;
    }
    .customPaddingStyle {
        padding-left: 50px !important;
    }
    .mdCustomPadding{
        padding-left: 50px;
    }
    .stepperStyle {
        padding-left: 50px;
    }
    .customAlignPaddingStyle{
        padding: 0px 12% 24px calc(4% + 50px);
    }
}

@media (max-width: 670px) {
    .mdFlexDisplay .loadingButtonStyle2 {
        margin-left: 0px !important;
        margin-top: 20px !important;
        display: block;
        width: 100%;
    }
}

@media (max-width: 599px) {
    .customPaddingStyle{
        padding: 0px !important;
    }
    .customAlignPaddingStyle{
        padding: 0px 4%;
    }
    .mdCustomPadding{
        padding: 0px !important;
    }
    .stepperStyle{
        padding: 10px 4% 0px 4%;
    }
    .stepperStyle .stepSlider{
        padding: 0px;
    }
    .stepperBtnStyle{
        margin-top: 0px !important;
    }
    .stepperBtnStyle button{
        margin-top: 0px !important;
        max-width: 90px;
        min-width: 90px;
    }
    .orderDiv{
        order: 3;
    }
    .extraPaddingStyle{
        padding: 0px !important;
    }
}
.icon-delete path {
    fill: black !important;
}