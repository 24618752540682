.faqBanner {
    padding: 0% 4% !important;
}

.faqBanner h6 {
    max-width: 340px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #048b67;
    margin-bottom: 16px;
}

.faqBanner .subHeadingStyle h3 {
    font-size: 34px;
    max-width: 540px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.56px;
    color: #27272a;
}

.faqBanner a {
    display: flex;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 26px;
    list-style: none;
    text-decoration: underline;
    color: #048b67;
    font-weight: 600;
    gap: 25px;
}

.faqBanner h4 {
    font-size: 28px;
    letter-spacing: -0.28px;
    color: #27272a;
    line-height: 36px;
}

/* .faqBanner .commonHeading {
    padding-top: 40px;
    padding-bottom: 20px;
} */

.faqBanner .commonSubHeding {
    margin: 0px;
}

.faqBanner .subText {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16;
    font-weight: normal;
}

.accordianStyle .MuiAccordionDetails-root {
    padding: 0px;
}

.accordianStyle .MuiCollapse-wrapperInner.MuiCollapse-vertical {
    padding-top: 0px;
}
.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
}
.MuiAccordion-root.Mui-expanded::before {
    opacity: 1;
}