/* heading styles */
.headingStyle h6{
    font-size: 16px;
    color: #048B67;
    font-weight: 600;
    margin-bottom: 10px;
}

.headingStyle h1{
    font-size: 56px;
    line-height: 60px;
    font-weight: 700;
    letter-spacing: -0.56px;
    color: #27272A;
}

.headingStyle h5, .addingSubHeading{
    letter-spacing: 0.2px;
    font-size: 20px;
    color: #27272A;
    font-weight: 400;
    line-height: 30px;
    margin-top: 12px;
}

.navLinksBtn{
    text-decoration: none;
    color: #59595A;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.headingStyle h3{
    font-size: 34px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: -0.56px;
    color: #27272A;
}

/* sub heading styles */
.subHeadingStyle h6{
    max-width: 340px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #048B67;
    margin-bottom: 16px;
}

.subHeadingStyle h3{
    font-size: 34px;
    max-width: 560px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.56px;
    color: #27272A;
}

.subHeadingStyle h2{
    font-size: 46px;
    font-weight: 700;
    letter-spacing: -0.46px;
    line-height: 52px;
    color: #27272A;
}

.subHeadingStyle h5{
    letter-spacing: 0.2px;
    font-size: 20px;
    color: #27272A;
    font-weight: 400;
    line-height: 30px;
    margin-top: 16px;
}

/* product details */
.productDetailsStyle{
    max-width: 320px;
    margin-top: 20px;
    padding-right: 22px;
}

.productDetailsStyle img{
    height: 47px;
}

.productDetailsStyle h6{
    font-size: 16px;
    color: #048B67;
    margin-top: 10px;
    font-weight: 600;
    margin-bottom: 6px;
    letter-spacing: 0px;
}

.productDetailsStyle p{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.16px;
    color: #27272A;
}


/* other asset */
.otherAssetStyle{
    height: 220px;
    padding: 23px 16px;
    border-radius: 6px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 8px #00000029;
}

.moolaMateStyle .productDetailsStyle p{
    max-width: 200px;
}

.moolaMateStyle h5{
    max-width: 585px;
    letter-spacing: 0.2px;
    font-size: 20px;
    color: #27272A;
    font-weight: 400;
    line-height: 30px;
    margin-top: 12px;
}       


.firstImgStyle{
    width: 22px;
    height: fit-content;
}

.secondImgStyle{
    width: 14px;
    height: fit-content;
}

.otherAssetStyle h6{
    font-size: 14px;
    color: #27272A;
    font-weight: 600;
    margin-bottom: 6px;
}

.otherAssetStyle p{
    font-size: 12px;
    color: #27272af0;
}

/*  */
.getStartedStyle h5{
    max-width: 540px;
    font-weight: 400;
}

.pageSubheading h3{
    text-align: center;
    max-width: 851px;   
    font-weight: 700;
    margin: 24px auto;
}

.pointerWidthStyle p{
   font-weight: 700;
}

.pointerWidthStyle span{
    font-weight: 700;
}

/* home hub menu style */
.hubMenuHeading{
    background: #FCFBF8;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 19px;
    margin-bottom: 32px;
    height: 70px;
    align-items: center;
}

.seachBarStyle form{
    width: 200px;
    display: flex;
    height: 40px;
    border: 2px solid #27272A;
    border-radius: 8px;
    padding-left: 16px;
    z-index: 1;
}

.seachBarStyle input{
    padding: 0px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #27272A;
}

.seachBarStyle img{
    width: 15px;
    height: 15px;
    margin-right: 20px;
}

.seachBarStyle {
    display: flex;
    align-items: center;
    justify-content: end;
}

.notificationMenuStyle{
    box-shadow: 0px 0px 4px #00000029;
    border-radius: 4px;
    padding: 4px 0px 10px 10px;
    margin-top: 10px !important;
    min-width: 288px;
    background: #fff;
    overflow: hidden;
    z-index: 99;
}

.notificationMenuStyle h6{
    letter-spacing: 0px;
    color: #048B67;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;    
}

.notificationMenuStyle svg{
    color: #048B67;
    margin-right: 4px;
}

ul.notificationText {
    margin-top: 8px;
    padding-top: 0px;
    padding-right: 6px;
    padding-left: 44px;
    overflow-y: auto;
    max-height: 146px;
}

.notificationText li:first-child{
    margin-top: 0px;
}

.notificationText::-webkit-scrollbar {
    width: 8px;
}

.notificationText::-webkit-scrollbar-track {
    background: #048B67;
    border-radius: 6px;
}

.notificationText::-webkit-scrollbar-thumb {
    background: #ebebeb; 
}

/* Hub subheading */
.hubPadding{
    padding-right: 4%;
    padding-left: 4%;
}

.hubCommonPadding{
    padding-top: 16px;
    padding-bottom: 33px;
}

.hubMenuHeading h6{
    letter-spacing: 0px;
    color: #048B67;
    font-size: 16px;
    line-height: 24px;   
    font-weight: 600; 
}

.hubMenuHeading p{
    color: #59595A;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.hubMenuHeading p svg{
    width: 21px;
    height: 21px;
    margin-left: 8px;
    cursor: pointer;
}

.activeNotification:after{
    content: "\A";
    width: 6px;
    right: 3px;
    top: 3px;
    height: 6px;
    border-radius: 50%;
    background: #b83b3b;
    display: inline-block;
    position: absolute;
}

.activeNotification {
    position: relative;
}

/* media query for heading style */
@media (max-width: 1400px) {
    .productDetailsStyle .loadingButtonStyle1 {
        padding: 4px 10px;
        font-size: 12px;
    }
}

@media (max-width: 1200px) {
    .productDetailsStyle, .subHeadingStyle h6, .subHeadingStyle h3, .moolaMateStyle .productDetailsStyle p, .moolaMateStyle h5, .getStartedStyle h5, .pointerWidthStyle p, span{
        max-width: initial;
    }
}

@media (max-width: 900px) {
    .hubMenuHeading{
        padding-right: 7%;
    }
    /* h1 style */
    .headingStyle h1, .subHeadingStyle h1, .productDetailsStyle h1, .otherAssetStyle h1{
        font-size: 36px;
        line-height: 40px;
    }
    /* h2 style */
    .headingStyle h2, .subHeadingStyle h2, .productDetailsStyle h2, .otherAssetStyle h2{
        font-size: 32px;
        line-height: 36px;
    }
    /* h3 style */
    .headingStyle h3, .subHeadingStyle h3, .productDetailsStyle h3, .otherAssetStyle h3{
        font-size: 26px;
        line-height: 30px;
    }
    /* h4 style */
    .headingStyle h4, .subHeadingStyle h4, .productDetailsStyle h4, .otherAssetStyle h4{
        font-size: 20px;
        line-height: 24px;
    }
    /* h5 style */
    .addingSubHeading, .headingStyle h5, .subHeadingStyle h5, .productDetailsStyle h5, .otherAssetStyle h5, .moolaMateStyle h5{
        font-size: 18px;
        line-height: 22px;
    }
    /* h6 style */
    .headingStyle h6, .subHeadingStyle h6, .productDetailsStyle h6, .otherAssetStyle h6{
        font-size: 16px;
        line-height: 20px;
    }
    .mobileWidthStyle{
        max-width: 900px !important;
    }
}

@media (max-width: 768px) {
    .hubMenuHeading{
        padding-right: 10%;
    }
    .seachBarStyle form {
        width: 160px;
    }
    .centerAlignStyle{
        justify-content: end !important;
    }
    .centerAlignStyle button{
        margin-right: 0px !important;
        margin-left: 10px;
        width: -webkit-fill-available;
    }
}

@media (max-width: 520px) {
    .pageSubheading h3{
        max-width: auto;
    }
    .hubMenuHeading {
        padding-right: 12%;
    }
    .tableFullWidthScroll{
        min-width: 700px;
    }
    .svgMobileIcon svg{
        width: 16px;
        height: 16px;
    }
    .mobileBtnStyle{
        display: flex;
    }
    .mobileBtnStyle .loadingButtonStyle1{
        max-width: 80px !important;
        min-width: 80px !important;
    }
    .mobileBtnStyle .loadingButtonStyle2{
        max-width: 80px !important;
        min-width: 80px !important;
    }
    .taskStyle{
        font-size: 14px;
    }
    .centerAlignStyle button{
        margin-left: 0px !important;
    }
}

@media (max-width: 420px) {
    .hubMenuHeading {
        padding-right: 15%;
    }
}