.payment-container {
    display: flex;
    justify-content: space-between;
}

.paypal {
    margin: 8px 0;
}

.payment-form {
    width: 80%;
}

.payment-input {
    padding: 12px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 8px 2px;
    resize: vertical;
    height: 40px;
    background: white;
    font-size: 14px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
}

.subscription-expiry-text {
    font-style: italic;
    font-size: 12px;
}

.subscription-info-card {
    background-color: #fcfbf7;
    border: 1px solid #0d9672;
    border-radius: 5px;
    padding: 20px;
    min-height: 400px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.subscription-details {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.subscription-info-card p {
    padding-top: 14px;
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 20px;
}

.subscription-info-card .subscribe-button {
    display: flex;
}
