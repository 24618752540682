.reviewDetailsStyle h4{        
    letter-spacing: -0.28px;
    color: #27272A;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 12px;
}

.reviewDetailsStyle p{ 
    letter-spacing: 0.16px;
    color: #27272A;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.reviewDetailsStyle span{ 
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.16px;
}

.reviewDetailsStyle {
    max-width: 630px;
}

@media (max-width: 1200px) {
    .reviewDetailsStyle {
        max-width: initial;
    }
    .mdDisplayShow{
        display: none;
    }
    .slickSliderStyle{
        justify-content: flex-start;
    }
}

@media (max-width: 1024px) {
    .reviewDetailsStyle h4{
        font-size: 20px;
    }
    .reviewDetailsStyle p, .reviewDetailsStyle span{ 
        font-size: 14px;
    }
    .reviewDetailsStyle{
        max-width: auto;
    }
   
}