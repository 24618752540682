/*  */
.hubTitleStyle{
    letter-spacing: -0.28px;
    color: #27272A;
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
}

.hubCardStyle{
    box-shadow: 0px 0px 4px #00000029;
    border-radius: 15px;
    padding: 20px 22px;
    height: 240px;
}

.dashboardStatus{
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.dashboardIcon{
    width: 32px;
    height: 32px;
}

.dashboardFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.descriptionHeading{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #27272A;
    margin-top: 38px;
}

.descriptionText{
    letter-spacing: 0.14px;
    color: #59595A;
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
    font-weight: 400;
}

.dashboardSubHeading {
    color: #048B67;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    font-weight: 600;
    padding-top: 0px;
    padding-bottom: 6px;
}

.listDisplayStyle .descriptionHeading{
    margin-top: 0px;
}

.listDisplayStyle{
    height: auto;
}

.listViewStyle{
    width: 100%;
    margin-left: 16px;
}

.listDisplayStyle .dashboardFlex{
    align-items: flex-start;
    margin-right: 14px;
    width: 22px;
}

.listDisplayStyle .dashboardIcon {
    width: 22px;
    height: 22px;
    margin-top: 10px;
}

.extraPadding{
    padding-top: 8rem;
}

@media (max-width: 520px) {
    .listDisplayStyle .dashboardFlex {
        width: 40px;
    }
    .smMobileWidth{
        margin: 0px;
        width: 100%;
    }
    .hubTitleStyle{
        font-size: 20px;
    }
    .hubCardStyle{
        height: auto;
    }
    .descriptionText{
        margin-bottom: 10px;
    }
}