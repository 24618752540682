.header {
    position: fixed;
    top: 0;
    width: 100%;
    background: white;
    z-index: 999;
}
.homeHeaderBanner img {
    height: 21px;
    object-fit: contain;
}

.homeHeaderBanner {
    width: 100%;
    left: 0px;
    padding: 25px 9%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-items: center;
    background: white;
}

.rightButtons {
    display: flex;
    margin-left: 15px;
}

.navigationBar {
    display: flex;
    align-items: center;
}

.rightButtons a {
    /* padding-right: 30px; */
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    position: relative;
}

.navCalActiveStyle:before {
    content: '-';
    display: block;
    position: absolute;
    top: -1px;
    left: -16px;
    font-size: 16px;
}

.navActiveStyle:before {
    content: '';
    display: block;
    background: url('../../../assets/images/homeImages/navActive.svg') no-repeat;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 6px;
    left: -16px;
}

.activeNavIcon:before {
    content: '';
    display: block;
    background: url('../../../assets/images/homeImages/activeNavIcon.jpg') no-repeat;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 9px;
    left: -16px;
}

.navigationBar a {
    margin-right: 36px;
}

.navInActiveStyle:before {
    content: '';
    display: block;
    background: url('../../../assets/images/homeImages/navInactive.svg') no-repeat;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 6px;
    left: -16px;
}

.hamburgerMenu {
    display: none;
}

.mobileNavBar a {
    text-decoration: none;
}

/* calculatorSubMenu */
.calculatorSubMenu {
    position: absolute;
    width: 100%;
    background: #fff;
    top: 80px;
    left: 0px;
    right: 0px;
    border-top: 1px solid #f4f1ea;
}

.activeNavItem svg {
    width: 18px;
    height: 20px;
    margin-right: 4px;
}

.activeNavItem img {
    height: auto;
    margin-right: 0px;
    position: absolute;
    top: 6px;
    left: -16px;
}

.activeNavItem {
    display: flex;
}

.calSubMenuStyle {
    width: 220px;
    z-index: 1;
    height: 280px;
}

.calSubMenuStyle a {
    display: block;
    padding: 6px 2px;
    letter-spacing: 0px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #27272a;
}

.calSubMenuStyle .MuiCollapse-wrapperInner.MuiCollapse-vertical {
    display: flex;
    justify-content: end;
    padding-top: 32px;
    padding-right: 16%;
}

.calSubMenuStyle p {
    letter-spacing: 0px;
    color: #27272a;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.calSubMenuStyle .dropDownMenuStyle li {
    display: block;
}

.calSubMenuStyle a {
    background: none;
    outline: none;
    border: none;
    padding: 6px 2px;
    letter-spacing: 0px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #27272a;
    cursor: pointer;
    font-family: 'Poppins';
}

.calculatorSubMenu .MuiCollapse-wrapperInner.MuiCollapse-vertical {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-right: 13rem;
    margin-top: 2rem;
}

.drawerMobileTab {
    display: none;
}
/* hamburger menu */
/* .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 26px;
    height: 26px;
    margin-top: 4px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.hamburger-icon span {
    display: block;
    height: 3px;
    background-color: #000;
    transition: transform 0.3s ease;
}

.hamburger-icon.open span:first-child {
    transform: rotate(45deg) translate(14px, 2px);
}

.hamburger-icon.open span:nth-child(2) {
    opacity: 0;
}

.hamburger-icon.open span:nth-child(3) {
    opacity: 0;
}

.hamburger-icon.open span:last-child {
    transform: rotate(-45deg) translate(14px, -2px);
}   */

/* media query */
@media screen and (max-width: 1920px) {
    .activeNavIcon:before {
        top: 10px;
    }
}

@media screen and (min-width: 1500px) {
    .calculatorSubMenu .MuiCollapse-wrapperInner.MuiCollapse-vertical {
        margin-right: 16rem;
    }
}

@media screen and (max-width: 1400px) {
    .activeNavIcon:before {
        top: 9px;
    }
}

@media screen and (max-width: 1200px) {
    .drawerMobileTab {
        display: block;
    }
    .navigationBar a {
        /* previous styles */
        display: none;
    }
    .hideButton,
    .smMobileView {
        display: none !important;
    }
    .navigationBar {
        display: none;
    }
    .hamburgerMenu {
        display: block;
    }
    .navInActiveStyle:before,
    .navActiveStyle:before {
        top: 22px;
        left: 10%;
    }
    .activeNavIcon:before {
        top: 26px;
        left: 10%;
    }
    .mobileNavBar {
        padding: 14px 15%;
        border-top: 1px solid #f3f3f3;
        position: relative;
    }
    a.hamburgerMenu {
        border-radius: 0px;
        padding: 8px 40px;
        margin: 0px !important;
    }
    .drawerMobileTab .MuiBackdrop-root.MuiModal-backdrop {
        background: none !important;
    }
    .mobileDrawer {
        display: block;
        padding: 14px 15%;
        font-size: 14px;
        border-radius: 0px !important;
        text-align: start !important;
    }
    .mobileNavBar a {
        font-size: 14px;
        font-weight: 500;
    }
    .calculatorSubMenu .MuiCollapse-wrapperInner.MuiCollapse-vertical {
        margin-right: 0px;
        padding-top: 0px;
        justify-content: start;
        padding-right: 0px;
        margin-top: 0px;
    }
    .calculatorSubMenu {
        position: initial;
        border: none;
    }
    .calSubMenuStyle {
        height: auto;
        padding-top: 20px;
    }
    .activeNavItem img {
        height: auto;
        margin-right: 6px;
        position: absolute;
        top: 20px;
        left: 10%;
    }
}

@media screen and (max-width: 768px) {
    .calculatorSubMenu .MuiCollapse-wrapperInner.MuiCollapse-vertical {
        display: block;
    }
    .calSubMenuStyle {
        width: auto;
    }
}

@media screen and (max-width: 520px) {
    .MuiCollapse-wrapperInner.MuiCollapse-vertical {
        display: block;
    }
    .calSubMenuStyle {
        width: auto;
    }
}

@media screen and (max-width: 440px) {
    .homeHeaderBanner {
        padding: 18px 4%;
    }
    .rightButtons a {
        padding: 2px 4px;
    }
    .mobileNavBar {
        padding: 14px 10%;
    }
    .navInActiveStyle:before,
    .navActiveStyle:before {
        top: 22px;
        left: 6%;
    }
    .activeNavIcon:before {
        left: 6%;
    }
    .activeNavItem img {
        left: 6%;
    }
}
.main {
    position: relative;
    top: 120px;
    margin-bottom: 120px;
}