.learnMoreStyle h5{
    max-width: 615px;
    font-weight: 400;
}

.learnMoreStyle h1{
    max-width: 980px;
}  

.learnMoreStyle p{
    font-size: 20px;
    max-width: 550px;
    letter-spacing: 0.2px;
    color: #27272A;
    line-height: 30px;
    margin-bottom: 30px;
}

.compartionTxtStyle .subHeadingStyle h5 {
    max-width: 500px;
    margin-top: 20px;
    font-weight: 400;
    margin-bottom: 20px;
}

.compartionTxtStyle p{
    font-size: 16px;
    max-width: 380px;
    line-height: 24px;
    letter-spacing: 0px;
    font-weight: 600;
    color: #048B67;
}

/* media query */
@media (max-width: 520px) {
    .compartionTxtStyle p, .learnMoreStyle p{
        font-size: 14px;
    }
    .learnMoreStyle h5, .learnMoreStyle h1, .learnMoreStyle p, .compartionTxtStyle .subHeadingStyle h5, .compartionTxtStyle p{
        max-width: inherit;
    }
}