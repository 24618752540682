/* financial reporting insightful */
ul.financialDetails {
    list-style: none;
}

ul.financialDetails li {
    display: flex;
    margin-top: 12px;
    font-size: 14px;
    color: #27272a;
    font-weight: 500;
}

ul.financialDetails li span {
    font-size: 16px;
    font-weight: 600;
}

ul.financialDetails img {
    height: 12px;
    margin-top: 6px;
    margin-right: 12px;
}

.commonSubText {
    letter-spacing: 0.2px;
    font-size: 20px;
    color: #27272a;
    font-weight: 400;
    line-height: 30px;
    margin-top: 14px;
}

/* calcultors component in home page */
.marginSmBottom {
    margin-bottom: 20px;
}

.marginTopBottom {
    margin-top: 26px;
    margin-bottom: 38px;
}

.otherAssetBorder {
    border-bottom: 1px solid #f4f1ea;
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 434px;
    margin-left: auto;
}

.moolahMateStyle .otherAssetBorder:first-child {
    padding-top: 0px;
}

.moolahMateStyle {
    padding-left: 60px;
}

.otherAssetBorder:last-child {
    border: none;
}

.calcultorHeading {
    font-size: 20px;
    color: #27272a;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.2px;
}

.calcultorSubHeading {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #27272a;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.curveIconStyle {
    position: absolute;
    top: -150px;
    right: -116px;
    width: 549px;
    height: 514px;
    z-index: -1;
}

/* more details */
.moreDetailsTxt {
    text-align: left;
    letter-spacing: 0.2px;
    line-height: 30px;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
}

.moreDetailsTxt span {
    /* margin-left: 6px; */
    font-size: 20px;
    letter-spacing: 0.2px;
    color: #009e64;
    font-weight: 600;
}

.get-more-options {
    margin-left: 6px;
}

/* circle */

.circleStyle {
    background: #f4f1ea;
    border: 2px solid #27272a;
    width: 34px;
    height: 34px;
    text-align: center;
    border-radius: 50%;
    line-height: 34px;
    margin-right: 25px;
}

.circleTxtStyle {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #27272a;
}

.pointerDivStyle {
    padding: 50px 30px;
    height: 310px;
    background-color: #fff;
    border-radius: 2px;
}

.easyStepMargin {
    position: relative;
    margin-top: -14rem;
    margin-right: 16rem;
    margin-bottom: 95px;
}

.getStartedImageStyle {
    width: 588px;
    height: 490px;
    margin-left: auto;
}

.mdPaddingBottom {
    padding-bottom: 100px;
}

.pointerDivStyle span {
    letter-spacing: 0.16px;
    color: #27272a;
    font-weight: 400;
    font-size: 16px;
}

.pointerDivStyle p {
    padding-top: 14px;
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 20px;
}

/* marquee texts */
.marqueeText {
    padding: 16px 12px 8px 12px;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    background: #29de94;
}

.marqueeText span {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.2px;
    margin-right: 60px;
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.mdPaddingBottom {
    padding-bottom: 120px;
}

/* why love moolah mate */
.headingPointerStyle {
    color: #27272a;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 8px;
    letter-spacing: 0.2px;
}

.subHeadingPointerStyle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #27272a;
}

/* get more details */
.getMoreDetails {
    margin-top: 2rem;
}

.getMoreDetails .headingStyle h1 {
    max-width: 555px;
}

.getMoreDetails .moreDetailsTxt {
    max-width: 568px;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
}

/*  Insightful Details */
.InsightfulDetails {
    max-width: 555px;
}

.subInsightfulHeading {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #27272a;
}

/* video component */
.videoPlayerStyle {
    margin-top: 30px;
    border-radius: 12px;
}

.otherPages {
    margin-top: 10px;
    padding-left: 60px;
}

.mobileMargin {
    margin-top: -100px;
}

.clientReviewStyle {
    margin-top: 16px;
}

.clientReviewStyle .clientReviewImg {
    width: 200px;
    margin: auto;
}

p.commaImg {
    font-size: 50px !important;
    font-style: italic;
    margin-bottom: -6px !important;
    margin-left: -10px;
}

.showIcon {
    display: none;
}

.moolahMateText {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #27272a;
    max-width: 585px;
    margin-top: 14px;
    font-weight: 400;
}

/* everything we need to more */
.moneyMorePaddingStyle {
    padding-top: 15%;
    margin-top: -10em;
}

.homeBannerStyle .headingStyle h1 {
    max-width: 600px;
}

.homeBannerStyle .headingStyle h5 {
    max-width: 547px;
}

.paddingRightStyle {
    padding-right: 48px;
}

/* media query for heading style */
@media (max-width: 1700px) {
    .mdPadding {
        padding-top: 100px;
    }
}

@media (max-width: 1540px) {
    .mdPaddingStyle {
        padding-bottom: 90px;
    }
    .getMoreDetails {
        margin-top: 1rem;
    }
    .pointerDivStyle {
        height: 380px;
    }
    .pointerWidthStyle {
        max-width: fit-content;
    }
    .moneyMorePaddingStyle {
        padding-top: 18%;
    }
    .easyStepMargin {
        margin-right: 14rem;
    }
}

@media (max-width: 1360px) {
    .easyStepMargin {
        margin-right: 12rem;
    }
    .paddingRightStyle {
        padding-right: 0px;
    }
}

@media (max-width: 1300px) {
    .getMoreDetails {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

@media (max-width: 1201px) {
    .videoPlayerStyle {
        margin-top: 10px;
    }
    .subHeadingPointerStyle {
        font-size: 14px;
    }
    .pointerWidthStyle {
        max-width: fit-content;
    }
    .curveIconStyle {
        height: 462px;
    }
    .otherAssetBorder {
        max-width: inherit;
    }
    .getMoreDetails {
        margin-top: 6rem;
    }
    .mobileMargin {
        margin-top: 0px;
    }
    .centerMobileImg {
        margin-top: -10px;
    }
    .headingPointerStyle {
        font-size: 16px;
    }
    .mdlistStyle .pointerStyle {
        width: 30px;
        height: 30px;
        line-height: 26px;
        margin-right: 12px;
    }
    .mdlistStyle .pointerStyle span {
        font-size: 16px;
    }
}

@media (max-width: 1199px) {
    .easyStepMargin {
        margin-right: 16rem;
    }
    .getStartedImageStyle {
        margin-top: -40px;
    }
    .pointerDivStyle {
        height: 260px;
    }
}

@media (max-width: 1024px) {
    .moolahMateStyle {
        padding-left: 0px;
    }
    .mdPaddingBottom {
        padding-bottom: 40px;
    }
    .getMoreDetails {
        margin-top: 3rem;
    }
}

@media (max-width: 900px) {
    .showIcon {
        display: block;
        text-align: center;
    }
    .getMoreDetails {
        margin-top: 1rem;
        margin-bottom: 0px;
    }
    .mobileSliderStyle {
        margin-top: -8rem;
        padding-top: 0px !important;
    }
    .mobileSliderStyle .slick-slide {
        float: right;
    }
    .mobileSliderStyle .pointerDivStyle {
        margin-right: 52px;
    }
    .mobileIconShow {
        display: none;
    }
    .moneyMorePaddingStyle {
        padding-top: 100px;
        margin-top: 0px;
    }
    .mdPaddingBottom {
        padding-bottom: 0px;
    }
    .hideMobileDiv {
        display: none;
    }
    .mobileFlexShow {
        display: flex;
        flex-direction: column;
        width: fit-content;
    }
    .mobileFlexShow .loadingButtonStyle1 {
        margin-left: 0px !important;
        margin-top: 10px;
        max-width: 220px;
    }
    .mobileSliderStyle .pointerWidthStyle {
        max-width: fit-content;
    }
    .mobileSliderStyle .pointerDivStyle {
        max-width: fit-content;
    }
    .otherPages {
        margin-top: 0px;
        padding-left: 0px;
    }
    .centerMobileImg {
        text-align: center;
        margin-top: -10px;
        width: inherit;
    }
    .mdPaddingBottom {
        padding-bottom: 40px;
    }
    .moneyMorePaddingStyle {
        padding-top: 40px;
        margin-top: 1em;
    }
    .slickSliderStyle button {
        margin-left: 6px;
    }
    .mobileSliderPadding {
        padding-right: 0px !important;
    }
}

@media (max-width: 700px) {
    .easyStepMargin {
        margin-right: 8rem;
        margin-top: -12rem;
    }
    .getStartedImageStyle {
        margin-top: -40px;
        width: auto;
        height: auto;
    }
    .pointerDivStyle {
        height: 300px;
    }
}

@media (max-width: 520px) {
    .pointerDivStyle {
        padding: 20px 30px;
    }
    .mobileMargin {
        margin-top: 0px !important;
    }
    .mobileOrder {
        order: 2;
    }
    .otherAssetStyle .marginTopSm {
        margin-top: 14px;
    }
    ul.financialDetails li span {
        font-size: 14px;
    }
    .otherAssetStyle {
        height: 230px;
    }
    .pointerDivStyle span {
        font-size: 14px;
    }
    .pointerDivStyle p {
        font-size: 18px;
    }
    .mobileSliderStyle {
        margin-top: -6rem;
    }
    .easyStepMargin {
        margin-right: 6rem;
        margin-top: -8rem;
    }
}

@media (max-width: 420px) {
    .footerBottomMenu {
        padding: 20px 6%;
    }
    .otherAssetStyle {
        height: auto;
    }
    .mobileGridView {
        display: block !important;
    }
    .mobileDivView {
        max-width: 100%;
    }
    .mobileBtnView {
        margin-top: 14px;
        margin-left: 0px !important;
    }
    .moneyMorePaddingStyle {
        padding-top: 60px;
        margin-top: 0px;
    }
    .firstImgStyle {
        width: 22px;
        height: 22px;
        object-fit: contain;
    }
    .mobileSliderStyle {
        margin-top: -4rem;
    }
    .easyStepMargin {
        margin-right: 4rem;
        margin-top: -7rem;
    }
}