.compareTable{
  width: 100%;
  border-spacing: 0px;
}

.tableOverFlow{
  text-align: center;
  background: #fff;
  border-collapse: collapse;
  box-shadow: 0px 0px 6px #00000029;
}

.activeHeader{
  color: #29DE94;
}

.compareTable th:nth-child(n+2),
.compareTable td:nth-child(n+2) {
  width: 15%;
}

.compareTable thead {
  background-color: black;
  color: white;
  padding: 10px;
}

.compareTable th.headerCell {
  color: #28c786;
}

.compareTable th, .compareTable td{
  padding: 10px;
  height: 70px;
}

.compareTable th{
  font-size: 20px;
}

.compareTable tr:nth-child(even) {
  background-color:
    #eefcf7;
}

.compareTable td:first-child {
  text-align: left;
  padding-left: 20px;
}

.compareTable td img {
  display: block;
  margin: 0 auto;
}

.tableWrapper{
  padding: 45px 9%;
}

.noIconStyle{
  width: 20px;
  height: 20px;
  display: block;
  margin: auto;
  border-radius: 50%;
  border: 2px solid #AAAAAA;
}

.learnHeading{
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.16px;
  color: #27272A;
}

@media (max-width: 900px) {
  .mobileHover{
    background: #f0fdf7;
    padding: 0px !important;
    width: 12% !important;
  }
}

@media (max-width: 520px) {
  .compareTable th {
    font-size: 14px;
  }
  .compareTable {
    font-size: 14px;
  }
  .compareTable th, td {
    padding: 6px;
  }
  .learnHeading{
    font-size: 14px;
    line-height: 20px;
  }
  .compareTable td:first-child {
    padding: 15px 0px 15px 15px;
    text-align: left;
    width: 8%;
  }
  .noIconStyle {
    width: 16px;
    height: 16px;
  }
  .compareTable td img {
    width: 14px;
  }
  .tableWrapper {
    padding: 20px 7%;
  }
  .tableOverFlow {
    overflow-y: scroll;
  }
  .activeHeader{
    background: #29de9442;
    width: 10% !important;
  }
}

@media (max-width: 480px) {
  .compareTable {
    width: 410px;
  }
  .tableOverFlow {
    overflow-y: scroll;
  }
}